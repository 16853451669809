import type { AvailablePurchaseOptionFixedPriceTicketDto } from '@peter-park/available-purchase-option'

/**
 * checks if ticket has all areas in it's list of areas
 * @param ticket the ticket to check
 * @param areaIds the area ids to search
 * @returns if the ticket has the areas or not
 * @remarks if ticket doesn't have areas, it will check the validity area
 */
export function ticketHasAllAreas(ticket: AvailablePurchaseOptionFixedPriceTicketDto, areaIds?: string[]) {
  const ticketAreaIds = new Set<string>(ticket.validityAreaEntityId)
  for (const area of ticket.areas || []) {
    ticketAreaIds.add(area.areaEntityId)
  }
  return areaIds?.every(id => ticketAreaIds.has(id))
}

/**
 * checks if ticket has area in its list of areas
 * @param ticket the ticket to check
 * @param areaId the area id to search
 * @returns if the ticket has the area or not
 * @remarks if ticket doesn't have areas, it will check the validity area
 */
export function ticketHasArea(ticket: AvailablePurchaseOptionFixedPriceTicketDto, areaId: string) {
  const areaIds = ticket.areas?.map(a => a.areaEntityId) ?? [ticket.validityAreaEntityId]
  return areaIds.includes(areaId)
}
