<script lang="ts" setup>
defineOptions({
  name: 'VerificationModal',
})

const props = defineProps<{
  open: boolean
}>()

const emits = defineEmits<{
  verify: []
  skipVerification: []
}>()

const { t } = useI18n()
</script>

<template>
  <UiModal vertical-position="top" :open="props.open" :over-header="false">
    <template #header>
      <img src="/code.svg" class="lg:w-xs">
    </template>
    <div class="flex gap-3">
      <div class="flex flex-col gap-4 p-3">
        <h2 class="text-sm font-bold">
          {{ t('verification_modal.title') }}
        </h2>
        <i18n-t keypath="verification_modal.description" tag="p" class="text-sm">
          <template #verification_code>
            <strong class="font-bold">{{ t('verification_modal.verification_code') }}</strong>
          </template>
        </i18n-t>
        <div class="mb-0 mt-auto flex flex-col items-center gap-6 sm:flex-row sm:gap-4">
          <UiButton @click="emits('verify')">
            {{ t('verification_modal.verify') }}
          </UiButton>
          <UiButton color="primary-inset" @click="emits('skipVerification')">
            {{ t('verification_modal.skip_verification') }}
          </UiButton>
        </div>
      </div>
      <span class="i-lucide:x cursor-pointer pr-4 text-2xl hidden lg:inline-block" @click="emits('skipVerification')" />
    </div>
  </UiModal>
</template>
