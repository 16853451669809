<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { userSettingsStore } from '@/stores'
import { useShopAuth } from '@/composables'
import { useGetShopByPath } from '@/composables/openapi/shop/useGetShopByPath'
import { logChangeLanguage } from '@/utils/logger'
import { useVerificationPrompt } from '@/composables/useVerificationPrompt'

const router = useRouter()
const route = useRoute()
const shopSlug = computed(() => {
  return route.params.shop as string
})
const userSettings = userSettingsStore()
const { userSelectedLanguage, latestShopVisited } = storeToRefs(userSettings)
const { t } = useI18n()

const { getShowVerificationPrompt, hideVerificationPrompt } = useVerificationPrompt()

const { handleLogin, handleLogout, isAuthenticated } = useShopAuth(latestShopVisited)

const languages = computed(() => {
  return [
    {
      label: t('english'),
      value: 'en',
      icon: 'i-circle-flags:gb',
    },
    {
      label: t('german'),
      value: 'de',
      icon: 'i-circle-flags:de',
    },
  ]
})

function setLanguage(language: string) {
  userSettings.setLanguage(language)
  logChangeLanguage(language)
}

function gotoAccount() {
  if (isAuthenticated.value)
    router.push({ name: 'account' })
  else
    handleLogin()
}
const { shopByPath } = useGetShopByPath(toRef(shopSlug), !!shopSlug.value)

const isAccountVerificationEnabled = computed(() => {
  return shopByPath.value?.isAccountVerification ?? false
})

const showVerificationModal = computed(() => {
  if (route.name === 'verify')
    return false

  return isAccountVerificationEnabled.value && getShowVerificationPrompt()
})
</script>

<template>
  <div
    class="min-h-screen w-full flex flex-col justify-between text-gray-900 font-sans transition duration-100 dark:bg-gray-900 dark:text-gray-50"
  >
    <AppHeader
      :selected-language="userSelectedLanguage"
      :languages="languages"
      :is-authenticated="isAuthenticated"
      :is-account-verification-enabled="isAccountVerificationEnabled"
      @update:selected-language="setLanguage"
      @login="handleLogin"
      @logout="handleLogout"
      @goto-account="gotoAccount"
    />
    <div class="mx-auto max-w-screen px-3 pb-3 pb-5 pt-5 sm:px-5 sm:pt-10">
      <slot />
    </div>
    <AppFooter :shop-country="shopByPath?.areaCountry" />
  </div>
  <VerificationModal :open="showVerificationModal" @skip-verification="hideVerificationPrompt" @verify="router.push({ name: 'verify' })" />
</template>
