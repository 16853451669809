import { defineStore } from 'pinia'

export const authentication = defineStore('authentication', () => {
  const accessToken = ref<string | undefined>(undefined)
  function setAccessToken(token: string) {
    accessToken.value = token
  }

  return { accessToken, setAccessToken }
})
