<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useNumberFormatter } from '@peter-park/vue-common-utils'
import { userSettingsStore } from '@/stores'
import type { Translations } from '@/types'

defineOptions({
  name: 'TicketCard',
})

const props = withDefaults(defineProps<TicketCardProps>(), {
  disabled: false,
  currencyCode: 'EUR', // TODO which fallback should we use?
  areas: () => [],
})

const emits = defineEmits<{
  /**
   * Emitted when the user clicks on the buy ticket button
   */
  selected: []
}>()

interface TicketCardProps {
  disabled?: boolean
  /**
   * The price of the ticket
   * @example 12.50
   */
  price: number
  /**
   * The currency code of the ticket
   * @example EUR, USD
   * @default EUR
   */
  currencyCode?: string
  /**
   * object containing translations for the ticket
   * @example ```typescript
    {
      en: { title: 'Parking Ticket' },
      de: { title: 'Parkticket' }
    }
     ```
   */
  translations: Translations
  /**
   * The areas where the ticket is valid
   * @example ['Area 1', 'Area 2']
   */
  areas?: string[]
}

const userSettings = userSettingsStore()
const { userSelectedLanguage } = storeToRefs(userSettings)

const priceFormatterRule = computed(() => {
  return useNumberFormatter(
    props.price,
    userSelectedLanguage,
    {
      style: 'currency',
      currency: props.currencyCode,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
  )
})
</script>

<template>
  <div
    class="h-full w-80 flex flex-col gap-2 rounded-3xl bg-gray-50 p-2 text-gray-900 transition duration-300 ease-in-out sm:w-100"
    data-testid="ticket-card"
  >
    <div class="h-full flex flex-col gap-2 rounded-2xl bg-white p-5">
      <slot name="icon" />
      <span class="text-2xl font-600 md:text-3xl">
        <UiDynamicTranslation class="break-words hyphens-auto" :translations="props.translations" translation-key="title" />
      </span>
      <template v-if="props.areas.length > 1">
        <div class="my-1 h-1px w-full bg-gray-50" />
        <MultiAreaPill :areas="props.areas" />
      </template>
      <div class="my-1 h-1px w-full bg-gray-50" />
      <div>
        <span class="text-xl font-600">
          {{ priceFormatterRule.formattedNumber.value }}
        </span>
        <span class="ml-2 text-xs color-gray-600">{{ $t('parking_tickets.tax_incl') }}</span>
      </div>
    </div>
    <UiButton class="end-0 mx-auto h-14 w-full" @click="emits('selected')">
      <span class="font-600">
        {{ $t('parking_tickets.buy_ticket') }}
      </span>
    </UiButton>
  </div>
</template>
