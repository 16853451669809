<script lang="ts" setup>
import defaultHeadingImage from '@/assets/landingPageDefault.avif'
import { getImageSrc } from '@/utils/s3'

const props = defineProps<{
  name?: string
  headingImage?: string | null
}>()

const headingImage = computed(() => {
  if (props.headingImage)
    return getImageSrc(props.headingImage)
  return defaultHeadingImage
})
</script>

<template>
  <div class="mx-auto max-w-250 w-full flex flex-col items-center gap-4 text-center md:pb-4">
    <span class="w-full break-words text-3xl text-gray-900 font-600 hyphens-auto md:text-5xl" data-testid="home-title">
      {{ $t('home.welcome', { name: props.name }) }}
    </span>
    <span class="w-full text-gray-600 md:pb-6 md:text-xl">{{ $t('home.subline') }}</span>
    <img class="max-h-md w-full rounded-xl object-cover md:block sm:max-h-xs" :src="headingImage" data-testid="desktop-logo">
  </div>
</template>
