import { computed, unref } from "vue";
export function useNumberFormatter(value, locale, options) {
  const formattedNumber = computed(() => {
    return new Intl.NumberFormat(unref(locale), unref(options)).format(
      unref(value)
    );
  });
  return {
    formattedNumber
  };
}
