<script lang="ts" setup>
import { computed } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import type { Language } from './types'

defineOptions({
  name: 'AppHeaderLanguageSelector',
})

const props = defineProps<
  {
    modelValue: Language['value']
    languages: Language[]
    wide?: boolean
  }
>()

const emit = defineEmits<{
  'update:modelValue': [value: Language['value']]
}>()

function handleSelectLanguage(language: string) {
  emit('update:modelValue', language)
}

const selectedLanguage = computed(() => {
  return (
    props.languages.find(item => item.value === props.modelValue)
  )
})

// Put always the selected country on top
const orderedLanguages = computed(() => {
  return [...props.languages].sort((a, b) => {
    if (a.label === selectedLanguage.value?.label)
      return -1
    return a.label.localeCompare(b.label)
  })
})
</script>

<template>
  <Listbox
    v-slot="{ open }" data-testid="language-selector-wrapper" as="div" :model-value="modelValue"
    class="relative" @update:model-value="handleSelectLanguage"
  >
    <ListboxButton
      data-testid="language-selector-opener"
      class="active:bg-highlight! ui-focus-visible:bg-highlight! h-7 w-full flex items-center justify-between gap-1 rounded-full p-1 outline-none ring-1 ring-gray-200 transition duration-300 ease-in-out bg-white! active:shadow active:ring-2 ui-focus-visible:ring-2 !hover:bg-gray-100"
      :class="[
        { 'outline-none border-none ring-none': open },
      ]"
    >
      <div data-testid="selected-language-icon" :class="selectedLanguage?.icon" class="block aspect-square h-full w-max object-contain" />
      <p v-if="wide" class="text-sm text-gray-700 font-500 capitalize">
        {{ selectedLanguage?.label }}
      </p>
      <div class="i-ri-arrow-down-s-line aspect-square h-full w-max text-gray-700" />
    </ListboxButton>
    <transition
      enter-active-class="transition duration-300 ease-out" enter-from-class="transform opacity-0"
      enter-to-class="transform opacity-300" leave-active-class="transition duration-200 ease-out"
      leave-from-class="transform opacity-300" leave-to-class="transform opacity-0"
    >
      <ListboxOptions
        class="absolute top-0 z-100 w-full rounded-3 shadow-lg ring-1 ring-gray-200 divide-y divide-gray-200 first:children:rounded-t-3 last:children:rounded-b-3"
        data-testid="language-selector-options"
      >
        <ListboxOption
          v-for="language in orderedLanguages" v-slot="{ active, selected }" :key="language.value"
          data-testid="language-selector-option" as="template" :value="language.value"
        >
          <li
            class="h-7 flex cursor-pointer items-center gap-1 p-1 text-base font-500 transition-300 hover:bg-gray-100" :class="[
              { 'bg-gray-300': active },
              { 'bg-purple-25! text-primary!': selected },
              { 'text-gray-900 bg-white': !selected },
              { 'pr-7': props.wide },
            ]"
          >
            <div class="block aspect-square h-full w-max object-contain" :class="language.icon" />
            <p
              class="text-sm text-gray-700" :class="[
                { uppercase: !props.wide },
                { 'capitalize text-center mx-auto': props.wide },
              ]
              "
            >
              {{ props.wide ? language.label : language.value }}
            </p>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </transition>
  </Listbox>
</template>
