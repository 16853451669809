<script lang="ts" setup>
import { TransitionChild, TransitionRoot } from '@headlessui/vue'

defineOptions({
  name: 'UiModal',
})
const props = withDefaults(defineProps<{
  open?: boolean
  to?: string
  /**
   * If true, the modal will be displayed over the header.
   */
  overHeader?: boolean
  verticalPosition?: 'top' | 'center' | 'bottom'
  horizontalPosition?: 'left' | 'center' | 'right'
}>(), {
  open: false,
  overHeader: true,
  to: 'body',
  verticalPosition: 'center',
  horizontalPosition: 'center',
})

const verticalPositionClasses = new Map([
  ['top', 'items-start'],
  ['center', 'items-end sm:items-center'],
  ['bottom', 'items-end'],
])

const horizontalPositionClasses = new Map([
  ['left', 'justify-start'],
  ['center', 'justify-center'],
  ['right', 'justify-end'],
])

const positionClasses = computed(() => {
  return [
    verticalPositionClasses.get(props.verticalPosition),
    horizontalPositionClasses.get(props.horizontalPosition),
  ]
})

const margins = computed(() => {
  return props.overHeader ? 'sm:my-8' : 'my-12 sm:my-20'
})
</script>

<template>
  <Teleport :to="props.to">
    <TransitionRoot as="template" :show="props.open">
      <div class="relative z-10">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-10 backdrop-blur-lg transition-opacity" aria-hidden="true" />
        </TransitionChild>

        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="min-h-full flex p-4 text-center sm:p-0" :class="positionClasses">
              <div class="relative transform overflow-hidden rounded-lg bg-white p-3 text-left shadow-xl transition-all lg:max-w-4xl sm:max-w-lg sm:w-full" :class="margins">
                <div class="flex flex-col gap-6 lg:flex-row">
                  <slot name="header" />
                  <slot />
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </TransitionRoot>
  </Teleport>
</template>
