<script lang="ts" setup>
defineOptions({
  name: 'AppHeaderButton',
})

const props = withDefaults(defineProps<buttonProps>(), {
  color: 'gray',
})
type Color = 'gray' | 'black'
interface buttonProps {
  notifications?: number
  color?: Color
  class?: string
}
const colorClassesMap = new Map<Color, string>([
  ['gray', 'ring-gray-200'],
  ['black', 'ring-black'],
])
</script>

<template>
  <div class="relative w-fit inline-flex">
    <div
      v-if="props.notifications"
      class="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block h-3 w-3 flex translate-x-2/4 items-center justify-center rounded-full bg-purple-900 text-center align-baseline text-[0.5rem] text-white font-700 -translate-y-1/2"
    >
      {{ props.notifications }}
    </div>
    <button
      class="h-7 min-w-7 flex items-center justify-center rounded-md bg-white ring-1 transition duration-300 ease-in-out hover:bg-gray-100"
      :class="[
        colorClassesMap.get(props.color),
        props.class,
      ]"
    >
      <slot />
    </button>
  </div>
</template>
