import { useQuery } from '@tanstack/vue-query'
import type { MaybeRef } from 'vue'
import { useOpenApiClient } from './useOpenApiClient'

export function useGetShopByPath(path: MaybeRef<string>, isEnabled = true) {
  const { apiInstance } = useOpenApiClient()
  const {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
  } = useQuery({
    queryKey: ['getShopByPath', path],
    queryFn: () => apiInstance.value.shopControllerGetOneByPath({ path: unref(path) }),
    enabled: isEnabled,
    networkMode: 'always',
  })
  return {
    getShopByPathIsLoading: isLoading,
    getShopByPathIsFetching: isFetching,
    getShopByPathIsError: isError,
    getShopByPathIsSuccess: isSuccess,
    getShopByPathError: error,
    getShopByPathRefetch: refetch,
    shopByPath: data,
    suspenseGetShopByPath: suspense,
  }
}
