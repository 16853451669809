<script lang="ts" setup>
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxItem,
  ComboboxRoot,
  ComboboxTrigger,
  ComboboxViewport,
} from 'radix-vue'

defineOptions({
  name: 'UiAreaSelection',
})

const props = defineProps<{
  /**
   * list of areas to show in the select
   */
  areas: {
    id: string
    name: string
  }[]
  /**
   * user can unselect all areas
   */
  allowUnselect?: boolean
}>()

/**
 * The id of the selected area
 */
const selectedAreas = defineModel<Array<string>>()

const { t } = useI18n()

const searchTerm = ref('')
const searchInput = ref<HTMLInputElement | null>(null)

const filteredAreas = computed(() => {
  return props.areas.filter((area) => {
    return area.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  })
})
watch(searchInput, () => {
  searchTerm.value = ''
  searchInput.value?.focus()
})

function calcIndicatorClass(areaId: string) {
  return selectedAreas?.value?.includes(areaId) ? 'i-ci:checkbox-check color-gray-600 ' : 'i-ci:checkbox-unchecked color-gray-600 '
}
</script>

<template>
  <ComboboxRoot v-model="selectedAreas" multiple>
    <ComboboxAnchor>
      <ComboboxTrigger class="h-auto max-w-md w-full inline-flex items-center justify-between gap-2 rounded-2 bg-white px-3.5 py-2 shadow-sm outline-none ring-1 ring-gray-300 ring-inset focus:ring-2 focus:ring-primary-300">
        <div class="i-lucide:map-pin h-5 w-5" />
        <p class="text-3.5 leading-5">
          {{ t('all_areas') }}
        </p>
        <div
          class="i-lucide:chevron-down h-5 w-5"
        />
      </ComboboxTrigger>
    </ComboboxAnchor>
    <ComboboxContent
      position="popper"
      align="start"
      :side-offset="5"
      class="SlideUpAndFade SelectContent will-change-[opacity,transform] z-100 mt-2 max-w-md w-md inline-flex rounded-2 bg-white pa-[0.375rem] shadow-sm ring-1 ring-gray-300 !p-0"
    >
      <div class="flex gap-2 b-gray-300 py-2.5 pl-2 pr-2" :class="filteredAreas.length > 0 ? 'b-b-1' : 'b-b-0'">
        <div class="i-lucide:search h-5 w-5" />
        <input ref="searchInput" v-model="searchTerm" :placeholder="t('search_area')" class="!outline-none">
      </div>

      <ComboboxViewport class="max-h-80 overflow-scroll">
        <ComboboxItem
          v-for="area in filteredAreas" :key="area.id" :value="area.id"
          :disabled="!props.allowUnselect && selectedAreas?.length === 1 && selectedAreas[0] === area.id"
          class="relative h-auto flex select-none items-center rounded-2 px-3 py-1.5 pl-2 pr-2 leading-none data-[disabled]:pointer-events-none data-[highlighted]:bg-gray-50 data-[highlighted]:text-black data-[highlighted]:outline-none"
        >
          <div
            class="h-5 w-5 font-500" :class="calcIndicatorClass(area.id)"
          />
          <p class="pl-2 text-3.5 text-gray-900 font-400">
            {{ area.name }}
          </p>
        </ComboboxItem>
      </ComboboxViewport>
    </ComboboxContent>
  </ComboboxRoot>
</template>
