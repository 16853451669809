import { useStorage } from '@vueuse/core'

/**
 * A composable function that return the status of the user verification.
 * The first check comes from the local storage, and it's per device.
 * The second check comes from the server,  and it's per user.
 */
export function useVerificationPrompt() {
  const showVerificationPrompt = useStorage('showVerificationPrompt', true)

  function getShowVerificationPrompt() {
    return showVerificationPrompt.value
  }
  function hideVerificationPrompt() {
    showVerificationPrompt.value = false
  }

  return {
    hideVerificationPrompt,
    getShowVerificationPrompt,
  }
}
