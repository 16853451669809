<script setup lang="ts">
import type { Translations } from '@/types'

const props = withDefaults(defineProps<{
  translations: Translations
  translationKey: string
  as?: string
}>(), {
  as: 'p',
})
const messages = toRef(props, 'translations')

const { t, setLocaleMessage } = useI18n({
  useScope: 'local',
})

watch(messages, (newMessages) => {
  Object.keys(newMessages).forEach((lang) => {
    setLocaleMessage(lang, newMessages[lang] ?? '')
  })
}, {
  immediate: true,
})
</script>

<template>
  <component :is="props.as">
    {{ t(translationKey) }}
  </component>
</template>
