import { useAuth0 } from '@auth0/auth0-vue'
import { storeToRefs } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router'
import { useShopAuth } from '@/composables'
import { authentication, userSettingsStore } from '@/stores'
import ShopView from '@/views/Shop/index.vue'
import { setSuperProperties } from '@/utils/logger'

function redirectToLatestShop() {
  const shop = localStorage.getItem('latestShopVisited')
  if (shop)
    return { name: 'shop-home', params: { shop } }

  return { name: 'error' }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'no-shop',
      redirect: redirectToLatestShop,
    },
    {
      path: '/callback',
      name: 'auth0-callback',
      component: () => import('@/views/Loading.vue'),
      meta: {
        layout: 'DefaultLayout',
        title: 'Loading Page',
      },
    },
    {
      path: '/callback-logout',
      name: 'auth0-callback-logout',
      redirect: redirectToLatestShop,
    },
    {
      path: '/email-verification-required',
      name: 'email-verification-required',
      component: () => import('@/views/EmailVerificationRequired.vue'),
      meta: {
        layout: 'NoFooterLayout',
        title: 'Email Verification Required',
        systemRoute: true,
      },
    },
    {
      path: '/:shop',
      name: 'shop-home',
      component: ShopView,
      meta: {
        layout: 'DefaultLayout',
        title: 'Shop Home',
        protected: true,
      },
    },
    {
      path: '/:shop/ticket/:ticketId',
      name: 'view-ticket',
      component: () => import('@/views/TicketDetails/index.vue'),
      meta: {
        layout: 'DefaultLayout',
        title: 'View Ticket',
        protected: true,
      },
    },
    {
      path: '/:shop/contact',
      name: 'shop-contact',
      component: () => import('@/views/Contact.vue'),
      meta: {
        layout: 'DefaultLayout',
        title: 'Contact Page',
        protected: true,
      },
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/views/Error.vue'),
      meta: {
        layout: 'DefaultLayout',
        title: 'Error Page',
        systemRoute: true,
      },
    },
    {
      path: '/loading',
      name: 'loading',
      component: () => import('@/views/Loading.vue'),
      meta: {
        layout: 'DefaultLayout',
        title: 'loading',
      },
    },
    {
      path: '/:shop/payment-success',
      name: 'payment-success',
      component: () => import('@/views/PaymentSuccess.vue'),
      meta: {
        layout: 'DefaultLayout',
        title: 'Payment Success Page',
      },
    },
    {
      path: '/:shop/payment-failed',
      name: 'payment-failed',
      component: () => import('@/views/PaymentError.vue'),
      meta: {
        layout: 'DefaultLayout',
        title: 'Payment Failed Page',
      },
    },
    {
      path: '/:shop/account',
      name: 'account',
      component: () => import('@/views/Account/index.vue'),
      meta: {
        layout: 'DefaultLayout',
        title: 'Account',
        protected: true,
      },
    },
    {
      path: '/:shop/verify',
      name: 'verify',
      component: () => import('@/views/Verify/index.vue'),
      meta: {
        layout: 'DefaultLayout',
        title: 'Verify',
        protected: true,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/views/Error.vue'),
      meta: {
        layout: 'DefaultLayout',
        title: 'Not Found',
        systemRoute: true,
      },
    },
  ],
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  },
})

router.beforeEach(async (to) => {
  // continue authentication process
  if (to.name === 'auth0-callback' || to.name === 'auth0-callback-logout')
    return

  const userSettings = userSettingsStore()

  // update latestShopVisited with the the value from route params
  if (to.params.shop)
    userSettings.setLatestShopVisited(to.params.shop as string)

  const { latestShopVisited } = storeToRefs(userSettings)

  // handle authentication
  const {
    checkSession,
    emailVerified,
    error,
    userId,
    nickname,
    handleLogin,
    isAuthenticated,
    metaLatestShopVisited,
  } = useShopAuth(latestShopVisited)

  const { setAccessToken } = authentication()
  const { getAccessTokenSilently } = useAuth0()

  await checkSession()

  setSuperProperties({
    shop_slug: latestShopVisited.value,
    user_id: userId.value,
    user_nickname: nickname.value,
  })

  // user is not authenticated, redirect to login page
  if (!unref(isAuthenticated)) {
    handleLogin()
    return
  }

  const accessToken = await getAccessTokenSilently()
  setAccessToken(accessToken)

  // user has not verified the email, redirect to a dedicated page
  if (!emailVerified.value && to.name !== 'email-verification-required')
    return { name: 'email-verification-required' }

  // restore last_shop_visited from auth0 metadata if there is nothing in the local storage
  if (!latestShopVisited.value || latestShopVisited.value === '') {
    if (metaLatestShopVisited.value) {
      userSettings.setLatestShopVisited(metaLatestShopVisited.value)
      return { name: 'shop-home', params: { shop: metaLatestShopVisited.value } }
    }

    // something bad happened while trying to restore last_shop_visited
    else {
      return { name: 'error' }
    }
  }

  // redirect every unhandled authentication error to the error page
  if (error.value && to.name !== 'error')
    return { name: 'error' }
})

export default router
