<script lang="ts" setup>
defineOptions({
  name: 'ShopView',
})
const router = useRouter()
const error = ref()

// the parent component handles the error thrown by the child component
// in this case the Suspense. Note that if we have nested Suspense components,
// the error will be caught by the closest parent onErrorCaptured
onErrorCaptured((err) => {
  error.value = err
  router.push({ name: 'not-found' })
  return false
})
</script>

<template>
  <div class="mx-auto w-full flex flex-col gap-8">
    <Suspense>
      <ShopContent />
      <template #fallback>
        <ShopContentLoading />
      </template>
    </Suspense>
  </div>
</template>
