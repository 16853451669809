<script setup lang="ts">
defineOptions({
  name: 'AppHeaderHamburgerMenu',
})

const props = withDefaults(defineProps<{
  mobileMenuOpen: boolean
}>(), {
  mobileMenuOpen: false,
})
</script>

<template>
  <Transition
    enter-active-class="transition duration-300 ease-out" enter-from-class="transform opacity-0"
    enter-to-class="transform opacity-300" leave-active-class="transition duration-200 ease-out"
    leave-from-class="transform opacity-300" leave-to-class="transform opacity-0"
  >
    <div v-if="props.mobileMenuOpen" class="absolute inset-0 top-3.55rem z-10 h-fit flex flex-col gap-2 border-b-1 border-b-gray-200 bg-white px-5 py-5 sm:hidden">
      <slot />
    </div>
  </Transition>
</template>
