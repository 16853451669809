import { type Auth0VueClientOptions, createAuth0 } from '@auth0/auth0-vue'

export const defaultAuthOptions: Auth0VueClientOptions = {
  domain: import.meta.env.VITE_ONLINESHOP_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_ONLINESHOP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: $AUTH0_REDIRECT_URI,
    audience: import.meta.env.VITE_ONLINESHOP_AUTH0_AUDIENCE,
  },
}
export const auth0 = createAuth0(defaultAuthOptions)
