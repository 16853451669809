export const domainDefaultLanguageMap = new Map([
  ['it', 'it'],
  ['ch', 'de'],
  ['de', 'de'],
  ['at', 'de'],
])

export function getAppDefaultLanguage() {
  // get the domain extension
  const domain = window.location.href
  const domainExtension = domain.split('.').pop() ?? ''
  const parsedDomainExtension = domainExtension.split('/')[0]
  return domainDefaultLanguageMap.get(parsedDomainExtension)
}
