<script setup lang="ts">
import type { Language } from './LanguageSelector/types'

defineOptions({
  name: 'AppHeader',
})

const props = defineProps<{
  languages: Language[]
  selectedLanguage: string
  isAuthenticated: boolean
  // until account page only has the verification feature we hide it from user if feature is disabled
  isAccountVerificationEnabled: boolean
}>()

const emit = defineEmits<{
  'update:selectedLanguage': [value: Language['value']]
  'login': []
  'logout': []
  'gotoAccount': []
}>()

const { t } = useI18n()

function handleUpdateSelectedLanguage(value: string) {
  emit('update:selectedLanguage', value)
}

const mobileMenuOpen = ref(false)
</script>

<template>
  <header
    class="relative z-302 flex justify-between border-b-1 bg-white px-5 py-2"
    data-testid="main-header-wrapper"
  >
    <div class="relative mx-auto flex justify-between container">
      <div>
        <router-link to="/">
          <img class="h-auto w-[160px]" src="@/assets/logo.svg" alt="Mobility Hub Logo">
        </router-link>
      </div>
      <div class="flex items-center justify-between gap-1 sm:gap-2">
        <template v-if="props.isAuthenticated">
          <AppHeaderButton v-if="props.isAccountVerificationEnabled" class="min-w-25 pa-2" @click="emit('gotoAccount')">
            <div class="row flex items-center">
              <p class="mr-2 text-center text-sm hidden sm:block">
                {{ t('my_profile') }}
              </p>
              <div class="i-lucide:user" />
            </div>
          </AppHeaderButton>
          <AppHeaderLanguageSelector
            :model-value="props.selectedLanguage"
            :languages="props.languages"
            class="hidden sm:block"
            @update:model-value="handleUpdateSelectedLanguage"
          />
          <AppHeaderButton class="min-w-25 pa-2" @click="emit('logout')">
            <div class="row flex items-center">
              <p class="mr-2 text-center text-sm">
                {{ t('logout') }}
              </p>
              <div class="i-lucide:log-out" />
            </div>
          </AppHeaderButton>
        </template>
        <AppHeaderButton v-else @click="emit('login')">
          <div class="i-lucide:log-in" />
        </AppHeaderButton>

        <AppHeaderHamburgerButton v-model:mobile-menu-open="mobileMenuOpen" />
      </div>
    </div>
    <AppHeaderHamburgerMenu :mobile-menu-open="mobileMenuOpen">
      <div class="flex flex-col gap-5 pt-5">
        <AppHeaderLanguageSelector
          :model-value="props.selectedLanguage"
          :languages="props.languages"
          wide
          @update:model-value="handleUpdateSelectedLanguage"
        />
      </div>
    </AppHeaderHamburgerMenu>
  </header>
</template>
